require("../style/fonts/elenar.css")
require("../style/main.css")
const m = require("mithril")

const nav = require("./nav")
const router = require("./router")
const content = require("./content")

const home = require("./home/home")
const article_list = require("./article_list/article_list")
const article = require("./article/article")
const about = require("./about/about")
const contact = require("./contact/contact")
const appointment = require("./appointment/appointment")

const footer = require("./footer")

const Site = {
    view: vnode => {
        if(router.get()[0]!=="loading"){
            return m(".site",[
                m(nav),
                m("main",[
                    ({
                        "home": m(home),
                        "article_list": m(article_list),
                        "article": m(article),
                        "about": m(about),
                        "contact": m(contact),
                        "appointment": m(appointment)
                    })[router.get()[0]],
                    m(footer)
                ])
            ])
        }
    }
}

m.mount(document.body, Site)
