require("../../style/article.css")
const m = require("mithril")
const content = require("../content")
const router = require("../router")
const link = require("../link")

function formatDate(date) {
  var monthNames = [
    "Januari", "Februari", "Maart",
    "April", "Mei", "Juni", "Juli",
    "Augustus", "September", "October",
    "November", "December"
  ];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return day + ' ' + monthNames[monthIndex] + ' ' + year;
}

module.exports = {
    view: vnode => {
        let page = content.get_post_by_id(router.get()[1])

        if(page){
            let related_items = content.get_related_posts(router.get()[1])
            //console.log(items)

            return m(".article",[
                m(".article__front",[
                    m(".article__categories",page.categories.map(cat=>m("span.article__category",cat.name))),
                    m(".article__title",m.trust(page.title)),
                    m(".article__image",m("img",{src:content.get_media(page.cover, "full")})),
                    m(".article__metadata",[
                        m(".article__metadata__date",["Geplaatst op ",formatDate(page.date)]),
                        (page.acf.auteur ? m(".article__metadata__date",["door ",page.acf.auteur]): []),
                        m(".article__share",[
                            "Deel op ",
                            m("a",{
                                href: "https://www.facebook.com/sharer/sharer.php?u=#"+window.location.href.replace("#", "%23"),
                                target: "_blank"
                            },"Facebook"),
                            ", ",
                            m("a",{
                                href: "http://www.twitter.com/intent/tweet?url="+window.location.href.replace("#", "%23"),
                                target: "_blank"
                            },"Twitter")
                        ])
                    ])
                ]),
                m(".article__content",m.trust(page.content)),
                m(".quote", [
                    m(".quote__content","Wil je ook zo’n project beginnen, maar weet je niet waar te beginnen? Wij helpen je!"),
                    m(link, {
                      class: "quote__button",
                      text: m("button","Maak een afspraak."),
                      link: ["appointment"]
                    })
                ]),
                (related_items.length>0) ? m(".article__related",[
                    m(".article__related__label","Gerelateerde Artikelen"),
                    m(".list__elements",related_items.map((i)=>{
                        return m(link, {
                            class: "list__element",
                            text:[
                                m(".list__element__tumbnail",m("img",{src:content.get_media(i.cover, "medium")})),
                                m(".list__element__categories", i.categories.map(cat=>m("span.list__element__category",cat.name))),
                                m(".list__element__title", i.title),
                                //m(".list__element__arrow", m(arrow))
                            ],
                            link: ["article", i.id]
                        })
                    })),
                ]) : []
            ])
        }
    }
}
