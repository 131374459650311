require("../../style/advies.css")
const m = require("mithril")
const link = require("../link")

module.exports = {
    view: vnode => {
        let values = vnode.attrs
        return m(".advies", [
            m(".advies__intro",m.trust(values.advies_introductie.replace("\n", "<br>"))),
            m("h2.advies__titel",values.advies_titel),
            m(".advies__opties",[
                m(link,{
                  class: "advies__optie",
                  text: [
                      m(".advies__optie__titel",values.advies_afspraak_titel),
                      m(".advies__optie__text",values.advies_afspraak_text),
                  ],
                  link: ["appointment"]
                }),
                m(link,{
                  class: "advies__optie",
                  text: [
                    m(".advies__optie__titel",values.advies_vraag_titel),
                    m(".advies__optie__text",values.advies_vraag_text),
                  ],
                  link: ["contact"]
                })
            ]),
        ])
    }
}
