require("../../style/inspiratie.css")
const m = require("mithril")
const content = require("../content")
const link = require("../link")

module.exports = {
    view: vnode => {
        let values = vnode.attrs
        let verbouwen = content.get_post_by_id(values.inspiratie_artikel_Verbouwen.ID)
        let duuzaamheid = content.get_post_by_id(values.inspiratie_artikel_Duurzaamheid.ID)
        return m(".inspiratie", [
            verbouwen ? m(link, {
                class: "inspiratie__blok",
                text:[
                  m("img",{src: content.get_media(verbouwen.cover, "full")}),
                  m(".inspiratie__type","Verbouwen"),
                  m(".inspiratie__titel",verbouwen.title)
                ],
                link: ["article", verbouwen.id]
            }) : [],
            duuzaamheid ? m(link, {
                class: "inspiratie__blok",
                text: [
                  m("img",{src: content.get_media(duuzaamheid.cover, "full")}),
                  m(".inspiratie__type","Duuzaamheid"),
                  m(".inspiratie__titel",duuzaamheid.title)
                ],
                link: ["article", duuzaamheid.id]
            }) : [],
        ])
    }
}
