const m = require("mithril")
const content = require("./content")
const history = require("history").createBrowserHistory()
let route = ["loading"]
//let base = "/ArchitectenWinkel/#/"
let base = "/"
//let base = "/proefversie/#/"

function url(route){
    if(route){
        return base+route.reduce((a,r)=>a+"/"+r)
    }
    return base
}

function set(r){
    function go(){
        console.log("go");
        window.scrollTo(0,0)
        route = r
        history.push(url(route), {
            route
        })
    }

    if(r[0]==="article_list"){
        content.load_posts_by_category(r[1]).then(go)
    } else if(r[0]==="appointment"){
        content.load_posts_by_category("winkel").then(go)
    } else {
        go()
    }


}

history.listen((location, action) => {
    if(action==="POP"){
        console.log(location, action)
        route = location.state.route
        m.redraw()
    }
})

Promise.all([content.load_pages(), content.load_categories(), content.load_posts()]).then(function(values) {
    console.log("loaded all");

    let whole_url = window.location.pathname
    if(whole_url){
        let load_url = whole_url.split("/")
        if(load_url[1]==="article_list"){
            set(["article_list", load_url[2]])
        } else if(load_url[1]==="article"){
            set(["article", load_url[2]])
        } else if(load_url[1]==="appointment"){
            set(["appointment"])
        } else if(load_url[1]==="about"){
            set(["about"])
        } else if(load_url[1]==="contact"){
            set(["contact"])
        } else {
            set(["home"])
            //history.push(base, {route})
        }
    } else {
        set(["home"])
        history.push(base, {route})
    }

});


module.exports = {
    get: _=> route,
    set: set,
    url: url
}
