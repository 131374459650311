require("../style/nav.css")
const m = require("mithril")
const content = require("./content")
const router = require("./router")
const link = require("./link")

//hide on scroll behaviour
let storeScroll = 0
let navscroll = 0
let popup = false

window.addEventListener("scroll", e=>{
    let delta = storeScroll - window.pageYOffset
    storeScroll = window.pageYOffset
    let before = navscroll

    if(window.pageYOffset>200){
        navscroll+=delta
        if(navscroll<-400) {
            navscroll=-400
        }
        if(navscroll>0) {
            navscroll=0
        }
    } else {
        navscroll = 0
    }
    if(before !== navscroll){
        m.redraw()
    }
})


module.exports = {
    view: vnode => {
        return m("nav", {
            style: "top: "+navscroll+"px;"
        },[
            m(".nav__left",[
                content.get_pages_by_slug(["bouwen", "verbouwen", "duurzaamheid"]).map((p)=>{
                    let active = p.slug===router.get()[1]
                    return m(link, {
                        class: "nav__element"+ (active?" --active": ""),
                        text: m.trust(p.title),
                        link: ["article_list", p.slug]
                    })
                }),
            ]),

            m(".nav__center",[
                //m(".nav__logo.--emphasis","DE ARCHITECTEN WINKEL")
                m(link,{
                    link: ["home"],
                    text: m(".nav__logo.--emphasis",{
                        style: "margin-top: "+navscroll+"px;"
                    },m("img",{src: "/img/Logo1.png"}))
                })
            ]),

            m(".nav__right",[
                m(link,{
                    class: "nav__element --emphasis"+(router.get()[0]==="appointment"?" --active": ""),
                    text: "Maak Afspraak",
                    link: ["appointment"],
                }),
                m(link,{
                    class: "nav__element"+(router.get()[0]==="about"?" --active": ""),
                    text: "Over",
                    link: ["about"]
                }),
                m(link,{
                    class: "nav__element"+(router.get()[0]==="contact"?" --active": ""),
                    text: "Contact",
                    link: ["contact"],
                })
            ]),

            //mobile nav
            m(".nav__hamburger", {
                onclick: _=> popup = true
            },m("img",{src: "/img/Hamburger.svg"})),

            m(".nav__popup",{
                class: popup ? "--open": "--closed"
            },[

                m(".nav__close",{
                    onclick: _=> popup = false
                }, m("img",{src: "/img/Hamburger__Close.svg"})),
                m(".nav__popup__section",[
                    m(link, {
                        onclick: _=> popup = false,
                        class: "nav__popup__element",
                        text: "Home",
                        link: ["home"]
                    })
                ]),
                m(".nav__popup__section",[
                    content.get_pages_by_slug(["bouwen", "verbouwen", "duurzaamheid"]).map((p)=>{
                        let active = p.slug===router.get()[1]
                        return m(link, {
                            onclick: _=> popup = false,
                            class: "nav__popup__element",
                            text: m.trust(p.title),
                            link: ["article_list", p.slug]
                        })
                    }),
                ]),
                m(".nav__popup__section",[
                    m(link,{
                        class: "nav__popup__element",
                        text: "Maak Afspraak",
                        link: ["appointment"],
                        onclick: _=> popup = false,
                    }),
                    m(link,{
                        class: "nav__popup__element",
                        text: "Over",
                        link: ["about"],
                        onclick: _=> popup = false,
                    }),
                    m(link,{
                        class: "nav__popup__element",
                        text: "Contact",
                        link: ["contact"],
                        onclick: _=> popup = false,
                    })
                ])

            ])
        ])
    }
}
