require("../../style/quote.css")
const m = require("mithril")
const link = require("../link")

module.exports = {
    view: vnode => {
        const values = vnode.attrs
        return m(".quote", [
            m(".quote__content",values.quote),
            m(".quote__button", m(link,{
                class: "nav__popup__element",
                text: m("button","Maak een afspraak."),
                link: ["appointment"],
            })),
        ])
    }
}
