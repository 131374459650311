require("../../style/about.css")
const m = require("mithril")
const content = require("../content")
const router = require("../router")
const link = require("../link")



module.exports = {
    view: vnode => {
        let page = content.get_page("over")
        console.log(page)
        return m(".article.about",[
            m(".article__content",m.trust(page.content)),
        ])
    }
}
