require("../../style/contact.css")
const m = require("mithril")
const content = require("../content")
const router = require("../router")
const link = require("../link")

module.exports = {
    view: vnode => {
        let page = content.get_page("contact")
        return m(".article.about",[
            m(".article__content",{
                oncreate: v=>{
                    let forms = v.dom.getElementsByTagName("form")
                    for (var i = 0; i < forms.length; i++) {
                      console.log(forms[i]);
                      let form = forms[i]
                      let action = form.getAttribute("action")

                      let formId = action.split("?")[1]

                      let submitUrl = page.link
                      form.setAttribute("action","")
                      form.addEventListener("submit", e=>{
                        e.preventDefault()
                        fetch(submitUrl, {
                          method: form.method,
                          body: new FormData(form)
                        }).then(r=>{
                            console.log(r)
                            let success = document.createElement('div')
                            success.setAttribute("class", "form_success")
                            success.innerHTML="Formulier verzonden!"
                            form.parentNode.insertBefore(success, form.nextSibling)
                            form.remove()
                        })
                      })
                    }
                }
            },m.trust(page.content)),
        ])
    }
}
