require("../style/footer.css")
const m = require("mithril")
const link = require("./link")

module.exports = {
    view: vnode => {
        return m(".footer", [
            m(".footer__column.footer__img__column",m(".footer__logo",m("img",{src:"/img/Logo2.png"}))),
            m(".footer__column", [
                m(".footer__column__section",[
                    m(link,{
                        class: "footer__option",
                        text: "Bouwen",
                        link: ["article_list", "bouwen"],
                    }),
                    m(link,{
                        class: "footer__option",
                        text: "Verbouwen",
                        link: ["article_list", "verbouwen"],
                    }),
                    m(link,{
                        class: "footer__option",
                        text: "Duurzaamheid",
                        link: ["article_list", "duurzaamheid"],
                    }),
                ]),
                m(".footer__column__section",[
                    m(link,{
                        class: "footer__option",
                        text: "Maak een afspraak",
                        link: ["appointment"],
                    }),
                ]),
                m(".footer__column__section",[
                    m(link,{
                        class: "footer__option",
                        text: "Over",
                        link: ["about"],
                    }),
                    m("a.footer__option",{
                        href: "https://www.bna.nl",
                        target: "_blank"
                    }, "BNA"),
                    m(link,{
                        class: "footer__option",
                        text: "Contact",
                        link: ["contact"],
                    }),
                ])
            ]),
            m(".footer__column", [
                m("a.footer__option",{
                    href: "https://www.facebook.com/Architectenwinkel/",
                    target: "_blank"
                },"Facebook"),
                m("a.footer__option",{
                    href: "https://twitter.com/architectwinkel",
                    target: "_blank"
                },"Twitter"),
                m("a.footer__option",{
                    href: "https://www.linkedin.com/company/architectenwinkel",
                    target: "_blank"
                },"Linkedin"),
            ]),

            m(".footer__column.footer__img__column",m(".footer__logo",m("img",{src:"/img/bna-logo-wit.svg"}))),
            m(".footer__column", [
                m(".footer__column__section",[
                    m(".footer__text","De Architectenwinkel maakt onderdeel uit van de Branche-vereniging Nederlandse Architectenbureaus."),
                    m("a.footer__text__link",{
                        href: "https://www.bna.nl",
                        target: "_blank"
                    },"www.bna.nl"),
                ]),
                m(".footer__column__section",[
                    m(".footer__text","Vind een BNA Erkend Architecten Bureau via zoekmachine Archy."),
                    m("a.footer__text__link",{
                        href: "https://www.archy.nu",
                        target: "_blank"
                    },"www.archy.nu"),
                ])
            ]),
        ])
    }
}
