require("../../style/article_list.css")
const m = require("mithril")
const content = require("../content")
const router = require("../router")
const arrow = require("../arrow")
const link = require("../link")

let selected = "alles"
module.exports = {
    view: vnode => {
        let page = content.get_page(router.get()[1])
        let items
        if(selected === "alles"){
            items = content.get_posts_by_page(router.get()[1])
        } else {
            items = content.get_posts_by_page_filtered(router.get()[1], selected)
        }

        let categories = content.get_posts_categories()

        if(page && items){
            return [
                m(".list__front",[
                    m.trust(page.content),
                    m(".list__front__button", m(link,{
                        class: "nav__popup__element",
                        text: m("button","Stel je vraag direct!"),
                        link: ["appointment"],
                    }))
                ]),
                m(".list",[
                    m(".list__categories",[
                        m("span.list__categories__label","Categorieën: "),
                        m("span.list__category",{
                            class: "alles"===selected ? "--selected": "",
                            onclick: _=> selected = "alles"
                        },"Alles"),
                        categories.map((c)=>m("span.list__category",{
                            class: c.slug===selected ? "--selected": "",
                            onclick: _=> selected = c.slug
                        },c.name))
                    ]),
                    m(".list__elements",items.map((i)=>{
                        return m(link, {
                            class: "list__element",
                            text:[
                                m(".list__element__tumbnail",m("img",{src:content.get_media(i.cover, "medium")})),
                                m(".list__element__categories", i.categories.map(cat=>m("span.list__element__category",cat.name))),
                                m(".list__element__title", i.title),
                                m(".list__element__arrow", m(arrow))
                            ],
                            link: ["article", i.id]
                        })
                    })),
                ])
            ]
        }
    }
}
