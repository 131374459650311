require("../../style/front.css")
const m = require("mithril")
const link = require("../link")

module.exports = {
    view: vnode => {
        const values = vnode.attrs
        return m(".front", [
            m(".front__line",[
                m(".front__block.front__block__left",[
                    m(".front__tagline",values.tagline),
                    m(".front__button", m(link,{
                        class: "nav__popup__element",
                        text: m("button","Maak een afspraak."),
                        link: ["appointment"],
                    })),
                ]),

                m(".front__block.front__block__right",[
                    m(".front__gallery",m("img",{src: values.introductie_afbeelding.url})),
                ]),
            ]),
            m(".front__line",[
                m(".front__block.--inset__left",[
                    m("h2",values.introductie_titel)
                ]),

                m(".front__block.--inset__right",[
                    m("p",values.introductie),
                ]),
            ])
        ])
    }
}
