require("../../style/highlights.css")
const m = require("mithril")
const link = require("../link")
const arrow = require("../arrow")

module.exports = {
    view: vnode => {
        const values = vnode.attrs
        return m(".highlights", [
            m(link,{
                class: "highlight",
                link: ["article_list", "bouwen"],
                text: [
                    m(".highlight__title","Bouwen"),
                    m(arrow),
                    m(".highlight__content",values.referentie_bouwen),
                ]
            }),
            m(link,{
                class: "highlight",
                link: ["article_list", "verbouwen"],
                text: [
                    m(".highlight__title","Verbouwen"),
                    m(arrow),
                    m(".highlight__content",values.referentie_verbouwen),
                ]
            }),
            m(link,{
                class: "highlight",
                link: ["article_list", "duurzaamheid"],
                text: [
                    m(".highlight__title","Duurzaamheid"),
                    m(arrow),
                    m(".highlight__content",values.referentie_duurzaamheid),
                ]
            }),
        ])
    }
}
