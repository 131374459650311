const m = require("mithril")

const content = {
    pages: [],
    pages_by_slug: {},
    categories: [],
    categories_by_slug: {},
    posts: {},
    posts_by_page: {},
    posts_by_page_filtered: {},
    related_posts: {},
    media: {}
}

let endpoint = "https://architectenwinkel.com/wordpress/"

function load_pages(){
    return m.request({
        method: "GET",
        url: endpoint+"index.php/wp-json/wp/v2/pages?per_page=100",
    })
    .then(function(result) {
        content.pages = result.map(p=>{
            return {
                slug: p.slug,
                title: p.title.rendered,
                content: p.content.rendered,
                order: p.menu_order,
                acf: p.acf,
                link: p.link
            }
        }).sort((a,b)=>a.order-b.order)

        content.pages_by_slug = {}
        content.pages.forEach(p=>content.pages_by_slug[p.slug] = p)
    })
}

function load_forms(){
    return m.request({
        method: "GET",
        url: endpoint+"index.php/wp-json/wp/v2/wpcf7_contact_form",
    })
    .then(function(result) {
        content.forms = result.map(form=>{
            let inputs = form._form.match(/<label>[\s\S]*?<\/label>/g)
            inputs = inputs.map(input=>{
                input = input.slice(7,-8)
                let i = input.match(/\[[\s\S]*?\]/g)[0].slice(1,-1).split(" ")
                let required = false
                if(i[0].indexOf("*")>-1){
                    required = true
                    i[0] = i[0].slice(0,-1)
                }
                return {
                    required,
                    type: i[0],
                    slug: i[1],
                    label: input.split("[")[0]
                }
            })
            return inputs
        })
        console.log(content.forms);
    })
}

function load_categories(){
    return m.request({
        method: "GET",
        url: endpoint+"index.php/wp-json/wp/v2/categories?per_page=100",
    })
    .then(function(result) {
        content.categories = {}
        content.categories_by_slug = {}
        result.forEach(p=>{
            if(!content.categories[p.id]) content.categories[p.id] = {children:[]}
            content.categories[p.id].id = p.id
            content.categories[p.id].name = p.name
            content.categories[p.id].slug = p.slug
            content.categories_by_slug[p.slug] = content.categories[p.id]

            if(!content.categories[p.parent]) content.categories[p.parent] = {children:[]}
            content.categories[p.parent].children.push(content.categories[p.id])
        })
    })
}

function load_posts(){
    m.request({
        method: "GET",
        url: endpoint+"index.php/wp-json/wp/v2/posts?per_page=100"
    }).then(function(result) {
        result.forEach(post=>{
            content.posts[post.id] = {
                id: post.id,
                title: post.title.rendered,
                categories: post.categories.map(cat=>content.categories[cat]),
                content: post.content.rendered,
                cover: post.featured_media,
                date: new Date(post.modified),
                acf: post.acf,
                link: post.link
            }
            load_media(post.featured_media)
            //if(!content.posts_by_page[category]) content.posts_by_page[category] = []
            //content.posts_by_page[category].push(content.posts[post.id])
        })
    })
}

function load_posts_by_category(category){
    if(!content.categories_by_slug[category]) {
        content.posts_by_page[category] = []
        return new Promise(r=>r())
    }

    let catid = content.categories_by_slug[category].id
    return m.request({
        method: "GET",
        url: endpoint+"index.php/wp-json/wp/v2/posts?per_page=100&categories="+catid,
    }).then(function(result) {
        content.posts_by_page[category] = []
        result.forEach(post=>{
            content.posts[post.id] = {
                id: post.id,
                title: post.title.rendered,
                categories: post.categories.map(cat=>content.categories[cat]),
                content: post.content.rendered,
                cover: post.featured_media,
                date: new Date(post.modified),
                acf: post.acf,
                link: post.link
            }
            load_media(post.featured_media)
            //if(!content.posts_by_page[category]) content.posts_by_page[category] = []
            content.posts_by_page[category].push(content.posts[post.id])
        })
        console.log(content.posts_by_page);
    })
}

function load_media(id){
    if(id && !content.media[id]){
        m.request({
            method: "GET",
            url: endpoint+"index.php/wp-json/wp/v2/media/"+id,
        }).then(function(result) {
            content.media[id]={
                thumb: result.media_details.sizes.thumbnail.source_url,
                medium: result.media_details.sizes.medium.source_url,
                full: result.media_details.sizes.full.source_url
            }
        })
    }
}

module.exports = {
    get_pages: cb=>content.pages.map(cb),
    get_page: slug=>content.pages_by_slug[slug],
    get_pages_by_slug: slugs=> slugs.map(slug=>content.pages_by_slug[slug]),
    get_category_by_id: id=>content.categories[id],
    get_posts_categories: _=> content.categories_by_slug["onderwerp"].children,
    get_media: (id, type)=>content.media[id] ? content.media[id][type] : "",

    get_post_by_id: id=> content.posts[id],
    get_posts_by_page: cat=> content.posts_by_page[cat],
    get_posts_by_page_filtered: (page, cat)=>{
        let catid = content.categories_by_slug[cat].id

        if(!content.posts_by_page_filtered[page]) content.posts_by_page_filtered[page] = {}
        if(!content.posts_by_page_filtered[page][cat]){
            content.posts_by_page_filtered[page][cat] = content.posts_by_page[page].filter(post=>{
                return post.categories.filter(cat=>cat.id===catid).length>0
            })
        }
        return content.posts_by_page_filtered[page][cat]
    },
    get_related_posts: oid=>{
        if(!content.related_posts[oid]){
            let current_cats = content.posts[oid].categories.map((cat)=>cat.id)
            content.related_posts[oid] = Object.keys(content.posts)
                .map(id=>{
                    let post = content.posts[id]
                    if(id==oid){
                        post.match = 0
                    } else {
                        post.match = post.categories.filter(cat=>{
                            return current_cats.indexOf(cat.id) > -1
                        }).length
                    }
                    return post
                })
                .filter(p=>p.match>0)
                .sort((a,b)=>a.match-b.match)
                .slice(0,3)
        }
        return content.related_posts[oid]

    },
    get_forms: _=>content.forms,
    load_categories,load_pages,
    load_posts_by_category,
    load_posts,
    load_forms
}
