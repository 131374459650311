require("../style/link.css")
const m = require("mithril")
const router = require("./router")

module.exports = {
    view: vnode => {
        return m("a", {
            class: vnode.attrs.class,
            href: router.url(vnode.attrs.link),
            onclick: e=>{
                router.set(vnode.attrs.link)
                e.preventDefault()
                if(vnode.attrs.onclick) vnode.attrs.onclick(e)
            }
        }, vnode.attrs.text)
    }
}
