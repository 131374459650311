require("../../style/appointment.css")
const m = require("mithril")
const content = require("../content")
const router = require("../router")
const link = require("../link")

let active_form = ""
let updated = false

module.exports = {
    view: vnode => {
        let page = content.get_page("maak-afspraak")
        let forms = content.get_posts_by_page("winkel")
        return m(".article.about",[
            m(".article__content",m.trust(page.content)),
            m("#map",{
                oncreate: e=>{
                    mapboxgl.accessToken = 'pk.eyJ1Ijoid29sa2VubWFjaGluZSIsImEiOiI5U21YdzNNIn0.cSp-n-GPeSBvZzqZYuxbbg';
                    var map = new mapboxgl.Map({
                        container: 'map',
                        style: 'mapbox://styles/mapbox/light-v10',
                        center: [5.37, 52.09], // starting position [lng, lat]
                        zoom: 6.2 // starting zoom
                    })
                    map.scrollZoom.disable()
                    map.addControl(new mapboxgl.NavigationControl())

                    let features = forms.map((form)=>{
                        return {
                            "type": "Feature",
                            "geometry": {
                                "type": "Point",
                                "coordinates": [form.acf.locatie.lng, form.acf.locatie.lat]
                            },
                            "properties": {
                                "slug": form.title,
                                "id": form.id
                            }
                        }
                    })
                    console.log(features);

                    map.on('load', function() {
                        map.loadImage('/img/Kaartpunt2.png', function(error, image) {
                            if (error) throw error;
                            map.addImage('dot', image);

                            map.addLayer({
                                "id": "shops",
                                "type": "symbol",
                                "source": {
                                    "type": "geojson",
                                    "data": {
                                        "type": "FeatureCollection",
                                        "features": features,
                                    }
                                },
                                "layout": {
                                    "icon-allow-overlap": true,
                                    "icon-image": "dot",
                                    "icon-size": 0.5
                                }
                            });
                        });

                        map.on('click', 'shops', function (e) {
                            // var coordinates = e.features[0].geometry.coordinates.slice();
                            let slug = e.features[0].properties.slug;
                            let id = e.features[0].properties.id;

                            active_form = forms.find(form=>form.id===id)
                            updated = true
                            m.redraw()
                        });

                        let popup = new mapboxgl.Popup({
                          closeButton: false,
                          closeOnClick: false
                        });

                        // Change the cursor to a pointer when the mouse is over the places layer.
                        map.on('mouseenter', 'shops', function (e) {
                            let coordinates = e.features[0].geometry.coordinates.slice()
                            let description = e.features[0].properties.slug
                            popup.setLngLat(coordinates).setHTML(description).addTo(map)
                            map.getCanvas().style.cursor = 'pointer';
                        });

                        // Change it back to a pointer when it leaves.
                        map.on('mouseleave', 'shops', function () {
                            map.getCanvas().style.cursor = '';
                        });
                    });
                }
            }),


            active_form ? m(".article__content.appointment__content",{
              oncreate: v=>{
                let domforms = v.dom.getElementsByTagName("form")
                for (var i = 0; i < domforms.length; i++) {
                  let form = domforms[i]
                  let action = form.getAttribute("action")

                  let formId = action.split("?")[1]


                  let submitUrl = `${active_form.link}?${formId}`
                  console.log(active_form, submitUrl);
                  form.setAttribute("action","")
                  form.addEventListener("submit", e=>{
                    e.preventDefault()
                    fetch(submitUrl, {
                      method: form.method,
                      body: new FormData(form)
                    }).then(r=>{
                        console.log(r)
                        let success = document.createElement('div')
                        success.setAttribute("class", "form_success")
                        success.innerHTML="Formulier verzonden!"
                        form.parentNode.insertBefore(success, form.nextSibling)
                        form.remove()
                    })
                  })
                }
                v.dom.scrollIntoView()
                updated = false
            },
            onupdate: v=>{
              if(updated){
                v.dom.scrollIntoView()
                updated = false
              }
            }
          },m.trust(active_form.content)) : [],
        ])
    }
}
