require("../style/arrow.css")
const m = require("mithril")
let w = 300
let cw = 300-2
let h = 20
let ch = 20-2
let back = 10

module.exports = {
    view: vnode => {

        return m("svg.arrow", {
            width: "100%",
            height: h,
            onupdate: e=>{
                if(e.dom.clientWidth!==w){
                    console.log(w)
                    w = e.dom.clientWidth
                    cw = w-2
                    m.redraw()
                }
            }
        },[
            m("line",{
                x1: 1, y1: ch/2,
                x2: cw, y2: ch/2,
            }),
            m("line",{
                x1: cw-back, y1: 1,
                x2: cw, y2: ch/2,
            }),
            m("line",{
                x1: cw-back, y1: ch,
                x2: cw, y2: ch/2,
            })
        ])
    }
}
